
; $ = global.$ = require("jQuery");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// (function($) {
// $(document).ready(function() {

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);



var initIt = {

    // browsers: function() {

    //     /*========================================
    //     =            check fb browser            =
    //     ========================================*/

    //     function isFacebookApp() {
           
    //     }

    //     if (isFacebookApp()) {
    //         // print('facebook app');
    //         browsers.isFacebook = true;
    //         $('html').addClass('isFacebook');
    //     } else {
    //         browsers.isFacebook = false;
    //     }


    //     return browsers;
    // },
    // checkFb: function() {
    //     var ua = navigator.userAgent || navigator.vendor || window.opera;
    //     return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    // },
    checkTouch: function() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }
}
initIt.touch = initIt.checkTouch();
// console.log(initIt);
initIt.exports = initIt
// return initIt;

// var browsers = {};

// // Opera 8.0+
// browsers.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// // Firefox 1.0+
// browsers.isFirefox = typeof InstallTrigger !== 'undefined';
// // Safari 3.0+ "[object HTMLElementConstructor]" 
// browsers.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
// // Internet Explorer 6-11
// browsers.isIE = /*@cc_on!@*/false || !!document.documentMode;
// // Edge 20+
// browsers.isEdge = !browsers.isIE && !!window.StyleMedia;
// // Chrome 1+
// browsers.isChrome = !!window.chrome && !!window.chrome.webstore;

// browsers.isWindowsPhone = (/windows phone/i.test(navigator.userAgent || navigator.vendor || window.opera));
// browsers.isAndroid = (/android/i.test(navigator.userAgent || navigator.vendor || window.opera));
// browsers.isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera)) && (!window.MSStream);

// for (var key in browsers) {
//     if (browsers.hasOwnProperty(key)) {
//         // console.log(key + " -> " + browsers[key]);
//         if (browsers[key]) {
//             $('html').addClass(key);
//         }
//     }
// }

// var isFacebook = false;
// if (initIt.checkFb()) {
//     var isFacebook = true;
//     $('html').addClass('isFacebook');
//     $('html body .site').prepend('isFacebook');
// }
// initIt.isFb = isFacebook;


/*===============================================
=            set browsers class html            =
===============================================*/

// var browsers = {};

// // Opera 8.0+
// browsers.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// // Firefox 1.0+
// browsers.isFirefox = typeof InstallTrigger !== 'undefined';
// // Safari 3.0+ "[object HTMLElementConstructor]" 
// browsers.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
// // Internet Explorer 6-11
// browsers.isIE = /*@cc_on!@*/false || !!document.documentMode;
// // Edge 20+
// browsers.isEdge = !browsers.isIE && !!window.StyleMedia;
// // Chrome 1+
// browsers.isChrome = !!window.chrome && !!window.chrome.webstore;

// browsers.isWindowsPhone = (/windows phone/i.test(navigator.userAgent || navigator.vendor || window.opera));
// browsers.isAndroid = (/android/i.test(navigator.userAgent || navigator.vendor || window.opera));
// browsers.isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera)) && (!window.MSStream);

// console.log(browsers);

// for (var key in browsers) {
//     if (browsers.hasOwnProperty(key)) {
//         // console.log(key + " -> " + browsers[key]);
//         if (browsers[key]) {
//             $('html').addClass(key);
//         }
//     }
// }

/*========================================
=            check fb browser            =
========================================*/

// function isFacebookApp() {
//     var ua = navigator.userAgent || navigator.vendor || window.opera;
//     return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
// }

// if (isFacebookApp()) {
//     // print('facebook app');
//     $('html').addClass('isFacebook');
// }

/*===================================
=            check touch            =
===================================*/


// function is_touch_device() {
//     var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
//     var mq = function(query) {
//         return window.matchMedia(query).matches;
//     }

//     if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
//         return true;
//     }

//     // include the 'heartz' as a way to have a non matching MQ to help terminate the join
//     // https://git.io/vznFH
//     var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
//     return mq(query);
// }
// console.log(is_touch_device());


// ie11fix
// objectFitImages();


// });
// })(jQuery);
; browserify_shim__define__module__export__(typeof initIt != "undefined" ? initIt : window.initIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
