// global.jQuery     = require('./Vendor/jquery-3.3.1.min.js');

global.jQuery     = require('./Vendor/jquery-3.3.1.min.js');
// var $ = require('jQuery');
var debounce   = require('js-throttle-debounce');
// var imagesLoaded    = require('imagesLoaded');


var Isotope    = require('isotope-layout');

// var Isotope    = require('./Vendor/isotope.js');

var cookIt = require('cookIt')


// var footerReveal     = require('./Vendor/footer-reveal.js');
var imagesLoaded     = require('./Vendor/imagesloaded.js');
// var niceSelect = require('./Vendor/jquery.nice-select.min.js');
// var WOW        = require('./Vendor/wow.min.js');
var WOW        = require('wow.js');
// require('./Vendor/footer-reveal.js');
require('waypoints/lib/jquery.waypoints.js');
// var flickity   = require('flickity');
// var flickIt        = require('./flickity_tt.js');
var flickIt        = require('flickIt');
// var cookIt        = require('cookIt');
var initIt       = require('initIt');

var bodyScroll = require('body-scroll-toggle');
var lottie = require('lottie-web');

var objectFitImages = require('object-fit-images');


// console.log(initIt);
(function($) {

$(document).ready(function() {


// var wow = new WOW();
// wow.init();

$('html').addClass('is_fading');

/*=====================================
=            footer reveal            =
=====================================*/

$.fn.footerReveal = function(options) {

    var $this = $(this),
        $site = $('.site'),
        $win = $(window),

        defaults = $.extend ({
          shadow : true,
          shadowOpacity: 0.8,
          zIndex : -100,
          isBottom : true
        }, options ),

        settings = $.extend(true, {}, defaults, options);

        // console.log($this.outerHeight(), 'this height');
        // console.log($win.outerHeight(), 'win height');

          // var winHeightWithoutNav = $win.outerHeight() - 150;
          var winHeightWithoutNav = $win.outerHeight() - 175;
        // console.log(winHeightWithoutNav, 'win height new');

      if ($this.outerHeight() <= winHeightWithoutNav || $this.outerHeight() <= winHeightWithoutNav && defaults.isBottom == false) { // before first || was: && $this.offset().top >= $win.outerHeight()

      if (defaults.isBottom) {
        $this.css({
          position : 'fixed',
          bottom : 0
        });
      } else {
        $this.css({
          position : 'fixed',
          top : 0
        });
      }

      if (defaults.shadow) {
        $site.css ({
          '-moz-box-shadow' : '0 20px 30px -20px rgba(0,0,0,' + defaults.shadowOpacity + ')',
          '-webkit-box-shadow' : '0 20px 30px -20px rgba(0,0,0,' + defaults.shadowOpacity +')',
          'box-shadow' : '0 20px 30px -20px rgba(0,0,0,' + defaults.shadowOpacity + ')'
        });
      }

      // $this.css({
      //   'width' : $site.outerWidth()
      // });
      if (defaults.isBottom) {
        $site.css({
          'margin-bottom' : $this.outerHeight()
        });
      } else {
        $site.css({
          'padding-top' : $this.outerHeight(),
          'margin-top' : 0
        });
      }
    } else {

      if (defaults.isBottom) {
        $this.css({
          position : 'relative',
          bottom : 'auto',
        });

        $site.css({
          'margin-bottom' : 'inherit',
        });
      } else {

        $this.css({
          position : 'relative',
          top : 'auto',
        });

        $site.css({
          'padding-top' : 'inherit',
        });
        
      }


      // $this.css({
      //   position : 'relative',
      //   bottom : 'auto',
      //   top: 'auto'
      // });

      // $site.css({
      //   'margin-bottom' : 'inherit',
      //   'margin-top' : 'inherit'
      // });
    }

    return this;

  };


/*===================================
=            global vars            =
===================================*/

var windowWidth = $(window).width();


/*================================
=            browsers            =
================================*/

var browsers = {};

// Opera 8.0+
browsers.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// Firefox 1.0+
browsers.isFirefox = typeof InstallTrigger !== 'undefined';
// Safari 3.0+ "[object HTMLElementConstructor]" 
browsers.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
// Internet Explorer 6-11
browsers.isIE = /*@cc_on!@*/false || !!document.documentMode;
browsers.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
// Edge 20+
browsers.isEdge = !browsers.isIE && !!window.StyleMedia;
// Chrome 1+
browsers.isChrome = !!window.chrome && !!window.chrome.webstore;

browsers.isWindowsPhone = (/windows phone/i.test(navigator.userAgent || navigator.vendor || window.opera));
browsers.isAndroid = (/android/i.test(navigator.userAgent || navigator.vendor || window.opera));
browsers.isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera)) && (!window.MSStream);

for (var key in browsers) {
    if (browsers.hasOwnProperty(key)) {
        // console.log(key + " -> " + browsers[key]);
        if (browsers[key]) {
            $('html').addClass(key);
        }
    }
}

// console.log(browsers);
/*=================================
=            barbar js            =
=================================*/


function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    // alert(ua);
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

if (isFacebookApp()) {
	$('html').addClass('isFacebook');
	// $('nav .nav-wrapper .menu-item').prepend('isFacebook');
}


if (browsers.isIE != true) {

var barba    = require('@barba/core');

barba.init({
	// preventRunning: true,
	debug: true,
	transitions: [{
		leave: function({ current, next, trigger }) {
			$('.trans-anim-page').removeClass('is-right');
			$('nav').addClass('is-locked');


			$('nav .conversory-logo').addClass('has-animation').on("animationend", function(){
      			$(this).removeClass('has-animation');
      		});
			// setTimeout(() => {
				var lineStandard = changeMenuLine($(trigger));
			// }, 1000);
			closeMenu();

			$('.site-footer').addClass('is-hidden');
			$('.site-footer').css('z-index', -1);

			// console.log(next.container);
			// console.log(next.url.href);
			// console.log(next.container.offsetParent.className);
			// lottie.play();
			// lottie.goToAndPlay(0);
			lottiAnim.goToAndPlay(0)
			lottiAnim2.goToAndPlay(0)
			// lottie.goToAndPlay(0);
			// var string = '';
			// string = next;
			if (next.url.href.indexOf('/tool/') !== -1 || next.url.href.indexOf('/service/') !== -1 || next.url.href.indexOf('/special-offer/') !== -1) { // next is single tools
				// console.log('tools page');
				$('.nav-inner-wrapper').addClass('is-hidden');
			}

			const done = this.async();
			setTimeout(function () {
				done();
			}, 500);
		},
		enter: function({ current, next, trigger }) {
			$('.site').addClass('is-hidden');
			setTimeout(function() {
				$('.trans-anim-page').css('transition', 'unset').addClass('is-right').removeClass('is-left');
			}, 100);
			setTimeout(function() {
				$('.trans-anim-page').removeAttr('style');
			}, 300);
		},
		afterEnter: function({ current, next, trigger }) {
			setTimeout(function() {
				if ($('.project-grid').length) {
					var iso = initIsotope('.project-grid', '.work-item', '.grid-stamp-text');
					setHashFilter(iso);
					initHoverServices(iso);
				}
				if ($('.team-grid').length) {
					var teamIso = initIsotope('.team-grid', '.member-item', false);
				}
				if ($('.services-grid').length) {
					var servicesIso = initIsotope('.services-grid', '.service-item', false);
				}
				if ($('.slider.slider-mobile-work').length) {
					flickIt.initSliders('init');
				}
				if (next.url.href.indexOf('/tool/') !== -1 || next.url.href.indexOf('/service/') !== -1 || next.url.href.indexOf('/special-offer/') !== -1) { // next is single tools
				} else {
					changeInverted();
				}
				// lottie.gotoAndStop(0);
				
			}, 300);
			setTimeout(function() {
				createTopWrapper();
			}, 400);
			setTimeout(function() {
				$('.header-reveal').footerReveal({ shadow: false, isBottom: false});
				if ($('.header-reveal').css('position') == 'fixed') {
					initWaypointHeaderReveal('.content-reveal');
				}
				bodyScroll.enable();
				pageScrollTop();
			}, 500);
			setTimeout(function() {
			}, 750);
			setTimeout(function() {
				$('.site').removeClass('is-hidden');
				$('.site').removeClass('has_animation');
				$('nav').removeClass('is-locked');
				repeatOften();
				// repeatOftenPopup();
				initPopupScroll();

			}, 930);
			setTimeout(function() {
				$('.site-footer').removeClass('is-hidden');
				$('.site-footer').css('z-index', '');
				$('footer').footerReveal({ shadow: false});

			}, 1500);
			setTimeout(function() {
				
				if (next.url.href.indexOf('/tool/') !== -1 || next.url.href.indexOf('/service/') !== -1 || next.url.href.indexOf('/special-offer/') !== -1) { // next is single tools/services
					$('.nav-inner-wrapper').removeClass('is-hidden');
				}
			}, 2500);
		}
	}],
	
	// prevent: ({ event }) => {
	// 	if (event.type === 'click') {
	// 		// Prevent the user to reload the site if a page transition is engaged.
	// 		if (barba.transitions.isRunning) {
	// 			event.preventDefault();
	// 			event.stopPropagation();

	// 			return true;
	// 		}
	// 	}
	// }
});

barba.hooks.afterLeave((data) => {
	// Set <body> classes for "next" page
	var nextHtml = data.next.html;
	var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
	var bodyClasses = $(response).filter('notbody').attr('class')
	$("body").attr("class", bodyClasses);
});

}

// barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus, container) {
// 	var iso = document.querySelector('.project-grid');
// 	// $('.project-grid').isotope( 'reloadItems' ).isotope();
// 	iso.arrange();
// });

function changeMenuLine($item) {
	var $line = $('.active-line-wrapper .active-line');
	var left = $item.position().left;
	var width = $item.outerWidth();
	$('.menu-item').removeClass('is-active');
	var lineCss = {};

	// console.log(left);

	if (!$('nav').hasClass('is-scrolled')) {
		left = left/1.15;
	}

	if ($item.hasClass('menu-item')) {
		if (!$item.hasClass('js_noMenuLineLink')) {
			lineCss = {'left': left + 'px', 'width': width + 'px'};
			$item.addClass('is-active');
		} else {
			lineCss = {'left': 0 + 'px', 'width': 0 + 'px'};
		}
	} else {
		lineCss = {'left': 0 + 'px', 'width': 0 + 'px'};
	}
	$line.css(lineCss);
	return lineCss;
}
// function saveDefaultLine() {
// 	return 
// }
// $('.menu-item').removeClass('is-active');
// $item.addClass('is-active');
if ($('.menu-item.is-active').length) {
	var $item = $('.menu-item.is-active');
	setTimeout(() => {
		
		var lineStandard = changeMenuLine($item);
	}, 1000);
	// setmouseMovesMenu(lineStandard);
}


/*==================================
=            set lottie            =
==================================*/

var prepath = $('html').attr('data-assets-url');

var lottiElem = document.querySelector('.conversory-logo-lotti-default');
var lottiAnim = lottie.loadAnimation({
  container: lottiElem, // the dom element that will contain the animation
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: prepath + '/Anim/conversory-lotti.json' // the path to the animation json
});

var lottiElem2 = document.querySelector('.conversory-logo-lotti-inverted');
var lottiAnim2 = lottie.loadAnimation({
  container: lottiElem2, // the dom element that will contain the animation
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: prepath + '/Anim/conversory-lotti-white.json' // the path to the animation json
});

// lottiAnim.addEventListener('complete', lottieComplete);
// animation.addEventListener('DOMLoaded', test3);

// function lottieComplete(){
// 	console.log('lottie complete');
//  //    lottie.stop();
// }


/*===========================================
=            object fit polyfill            =
===========================================*/

if (browsers.isIE11 == true) {
	objectFitImages();
}



/*===================================
=            repeatOften            =
===================================*/

// function repeatOften() {
// 	console.log('working');
//     if ($(window).scrollTop() != 0) {
//         // if (!$('nav').hasClass('is_scrolled')) {
//         //     $('nav').addClass('is_scrolled');
//         // }
//     } else {
//         // if ($('nav').hasClass('is_scrolled')) {
//         //     $('nav').removeClass('is_scrolled');
//         // }

//     }
// }
// $(document).on('hover', '.jsFollowMouseHover', function() {
//     requestAnimationFrame(repeatOften);
// });

// $(window).on('scroll', function() {
//     requestAnimationFrame(repeatOften);
// });

function repeatOften() {
	
    if ($(window).scrollTop() != 0) {
        if (!$('nav').hasClass('is-scrolled')) {
            $('nav').addClass('is-scrolled');
            $('.trans-anim').addClass('is-scrolled');
        }
    } else {
        if ($('nav').hasClass('is-scrolled')) {
            $('nav').removeClass('is-scrolled');
            $('.trans-anim').removeClass('is-scrolled');
        }
    }
}

function repeatOftenPopup() {
    if ($('.popup').scrollTop() != 0) {
		if (!$('.nav-fake').hasClass('is-scrolled')) {
            $('.nav-fake').addClass('is-scrolled');
        }
    } else {
		if ($('.nav-fake').hasClass('is-scrolled')) {
            $('.nav-fake').removeClass('is-scrolled');
        }
    }
}
function initPopupScroll() {

	$('.popup').on('scroll', function() {
// $(document).on('scroll', '.popup', function() {
	// $(window).on('scroll', '.popup', function() {
		// console.log('hello pupup');
		requestAnimationFrame(repeatOftenPopup);
	});
	repeatOftenPopup();
}
initPopupScroll();


$(window).on('scroll', function() {
	// console.log('hello?')
	requestAnimationFrame(repeatOften);
});
repeatOften();


// document.querySelector('.popup').addEventListener('scroll', () => {
//     console.log('scroll event fired!')
//  });

// if ($('.popup ').length != 0) {

// }


/*=======================================
=            page scroll top            =
=======================================*/

function pageScrollTop() {
	// console.log('page scrolled top')
	document.body.scrollTop = document.documentElement.scrollTop = 0;
}


/*====================================
=            init isotope            =
====================================*/



function initIsotope($isotope, $itemSel, $stamp) {
	var options = {
		itemSelector: $itemSel,
		percentPosition: true,
		stamp: $stamp,
	  	masonry: {
			columnWidth: '.grid-sizer'
	  	}
	}
	if ($stamp === false) {
		delete options.stamp;
	}
	var elem = document.querySelector($isotope);
	var iso = new Isotope( elem, options);
	// if ($imagesLoaded !== false) {
	var $iso = $(iso);
	// console.log('init iso');


	// imagesLoaded.on( 'progress', function( instance ) {
	//   console.log('ALWAYS - all images have been loaded');
	// });#

	// imgLoad.on( 'done', function( instance ) {
	//   console.log('DONE  - all images have been successfully loaded');
	// });
	// console.log();
	
	
	var $images = $(iso.element).find('.image-wrapper img');
	if ($images.length != 0) {
		$images.each(function(index, el) {
			$(el).imagesLoaded().progress( function(instance, image) {
				iso.layout();
			});
		});
	}

	// $images.imagesLoaded().done( function(instance, image) {

	// 	console.log('relayout');
	// 	console.log(instance);
	// 	console.log(image);
	// 	// $iso.isotope('layout');
	// 	// iso.layout();
	// });

	// $iso.imagesLoaded().done( function(instance, image) {

	// 	console.log('relayout');
	// 	console.log(instance);
	// 	console.log(image);
	// 	// $iso.isotope('layout');
	// 	// iso.layout();
	// });

	// }
	return iso;
}


/*====================================
=            project grid            =
====================================*/


if ($('.project-grid').length) {
	// setTimeout(function() {

		var iso = initIsotope('.project-grid', '.work-item', '.grid-stamp-text');
		setHashFilter(iso);	
		initHoverServices(iso);

	// }, 400);
}

function setHashFilter(iso) {
	if ($('.project-grid').hasClass('has_filter_set')) {
		var filter = $('.project-grid').attr('data-filter');
		iso.arrange({
			filter: '.' + filter,
		});
	}
}


/*===================================================
=            show services for filtering            =
===================================================*/
function initHoverServices(iso) {
	$(document).on('mouseenter', '.js_showServices', function() {
		
		var ww = $(window).outerWidth();
		var $this = $(this);
		var $filters = $this.closest('.project-grid').find('.services-filter');

		var $serviceSpan = $this;

		console.log(iso);

		if (ww > 900) {
			$serviceSpan.addClass('is-active');

			$filters.addClass('set-height');
			// console.log('mouseenter');
		  	setTimeout(function() {
				// console.log('trigger iso arrange');
				// console.log(iso);
				iso.arrange();
			}, 100);

			setTimeout(function() {
				$filters.addClass('is-visible');
			}, 333);
		}

	});
	$(document).on('mouseleave', '.js_closeServices .text', function() {
		var $this = $(this);
		var ww = $(window).outerWidth();
		if (ww > 900) {
			closeOpen($this);
		}
	});

	$(document).on('click', '.js_showServices', function() {

		var ww = $(window).outerWidth();
		var $this = $(this);
		var $filters = $this.closest('.project-grid').find('.services-filter');

		var $serviceSpan = $this;

		if (ww <= 900) {
			if ($serviceSpan.hasClass('is-active')) {
				$('.js_filterService').removeClass('is-active');
				iso.arrange({
					filter: '*',
				});

				closeOpen($this);
			} else {

				$serviceSpan.addClass('is-active');

				$filters.addClass('set-height');
				// console.log('mouseenter');
			  	setTimeout(function() {
					iso.arrange();
				}, 100);

				setTimeout(function() {
					$filters.addClass('is-visible');
				}, 333);
			}
		} else {
			// if ($this.hasClass('is-active')) {
				$('.js_filterService').removeClass('is-active');
				iso.arrange({
					filter: '*',
				});

				closeOpen($this);
		}





		// var ww = $(window).outerWidth();
		// var $this = $(this);
		// if (ww > 900) {
			// if ($this.hasClass('is-active')) {
			// 	$('.js_filterService').removeClass('is-active');
			// 	iso.arrange({
			// 		filter: '*',
			// 	});

			// 	closeOpen($this);

			// } else {
				
			// }s


	});


	$(document).on('click', '.js_closeServices .text', function() {
		var $this = $(this);
		var ww = $(window).outerWidth();
		// if (ww <= 900) {
		// 	closeOpen($this);
		// }
	});

	/*======================================
	=            filter service            =
	======================================*/

	$(document).on('click', '.js_filterService', function(e) {
		e.stopPropagation();

		console.log('hello?');

		var $this = $(this);
		var filter = $this.attr('data-filter');
		$('.js_filterService').removeClass('is-active');
		$this.addClass('is-active');
	    // console.log('.' + filter);

		// console.log(iso, 'iso');
		iso.arrange({
			filter: '.' + filter,
		});
		// iso.arrange();
	});

	/*==============================================
	=            close on click servies            =
	==============================================*/
	
	$(document).on('click', '.js_showServices', function(e) {
		
		// if ($this.next().hasClass('is-visible')) {
		// }

		// var ww = $(window).outerWidth();
		// var $this = $(this);
		// // if (ww > 900) {
		// 	if ($this.hasClass('is-active')) {
		// 		$('.js_filterService').removeClass('is-active');
		// 		iso.arrange({
		// 			filter: '*',
		// 		});

		// 		closeOpen($this);

		// 	} else {

		// 	}
			
		// }

	});
	

	function closeOpen($this) {
		var $filters = $this.closest('.project-grid').find('.services-filter');

		var $serviceSpan = $this.closest('.project-grid').find('.js_showServices');

		$serviceSpan.removeClass('is-active');


		// console.log('mouseleave');
		if ($('.js_filterService.is-active').length == 0) {
			// console.log('mouseleave x2');
			setTimeout(function() {
				$filters.removeClass('is-visible');
			}, 250);
		  	setTimeout(function() {
				$filters.removeClass('set-height');
				iso.arrange();
			}, 350);
		}
	}
	
}


/*=====================================================================
=            check svg path/line/poly length for animation            =
=====================================================================*/

// var path = document.querySelectorAll('.svg-logo path, .svg-logo polygon');
// $(path).each(function(index, el) {
// 	var length = el.getTotalLength();
// 	console.log(length);
// });

/*=================================
=            team grid            =
=================================*/

if ($('.team-grid').length) {
	setTimeout(function() {

		// var elem = document.querySelector('.team-grid');
		// var teamIso = new Isotope( elem, {
		//   	// options
		// 	itemSelector: '.member-item',
		// 	percentPosition: true,
		//   	masonry: {
		// 		columnWidth: '.grid-sizer'
		//   	}
		// });

		// $(teamIso).imagesLoaded().progress( function() {
		// 	$(teamIso).isotope('layout');
		// });
		var teamIso = initIsotope('.team-grid', '.member-item', false);
	}, 400);
}

/*=================================
=            service grid            =
=================================*/

if ($('.services-grid').length) {
	setTimeout(function() {

	// var elem = document.querySelector('.services-grid');
	// var servicesIso = new Isotope( elem, {
	//   	// options
	// 	itemSelector: '.service-item',
	// 	percentPosition: true,
	//   	masonry: {
	// 		columnWidth: '.grid-sizer'
	//   	}
	// });

	// $(servicesIso).imagesLoaded().progress( function() {
	// 	$(servicesIso).isotope('layout');
	// });
	var servicesIso = initIsotope('.services-grid', '.service-item', false);
	}, 400);
}

/*=========================================
=             open/close popup            =
==========================================*/

$(document).on('click', '.js_openPopUp', function() {
	var $this = $(this);
	var $nav = $('.nav');
	var slug = $this.attr('data-popup-slug');
	// var $popupWrapper = $this.closest('.popup-wrapper').next();
	var $popupWrapper = $this.closest('.site').find('.popup-content');
	var $popup = $popupWrapper.find('.popup[data-popup-slug="' + slug + '"]');
	$popup.css('display', 'block');

	setTimeout(function() {
		$popup.addClass('is-open');
		$nav.addClass('is-hidden');
	}, 10);
});

$(document).on('click', '.js_closePopUp', function() {
	var $this = $(this);
	var $popup = $(this).closest('.popup');
	var $nav = $('.nav');

	$popup.removeClass('is-open');
	$nav.removeClass('is-hidden');
	setTimeout(function() {
		$popup.css('display', 'none');
	}, 400);
});

/*==========================================
=            change footer info            =
==========================================*/
// var heightFirst = $('.site-footer .box .info-1').outerHeight();

if (browsers.isIos) {
	setTimeout(function() {
		var $box = $('.site-footer .box-1');
		var maxHeight = 0;
		$('.site-footer .box-1 .info').each(function(i, e){
		   	var thisH = $(this).outerHeight();
			if (thisH > maxHeight) { maxHeight = thisH; }
		});
		$box.css('height', maxHeight + 'px');
	}, 1000);
} else {
	setTimeout(function() {
		var $box = $('.site-footer .box-1');
		var maxHeight = 0;
		$('.site-footer .box-1 .info').each(function(i, e){
		   	var thisH = $(this).outerHeight();
			if (thisH > maxHeight) { maxHeight = thisH; }
		});
		$box.css('height', maxHeight + 'px');
	}, 200);
}


// function setFooterBoxHeight() {

// }

// $(document).on('click', '.js_changeFooterInfo', function() {
// 	var $this = $(this);
// 	var index = $this.attr('data-index');
// 	var $activeInfo = $('.site-footer .box .info-' + index);
// 	var $box = $('.site-footer .box-1');
// 	var height = $activeInfo.outerHeight();

// 	// $(this).hide().html(services).addClass('is_inline').removeClass('link link-hover-parent link-hover-line').slideDown();
// 	$('.site-footer .box .info').fadeOut();
// 	$('.js_changeFooterInfo').removeClass('is-active');
// 	$this.addClass('is-active');
// 	$box.css('height', height);

// 	$activeInfo.fadeOut().removeClass('is-hidden').fadeIn();
// });

$(document).on('mouseenter touchenter', '.js_changeFooterInfo', function() {
	var $this = $(this);
	var index = $this.attr('data-index');
	var $activeInfo = $('.site-footer .box .info-' + index);
	var $box = $('.site-footer .box-1');
	var height = $activeInfo.outerHeight();

	// console.log('footer enter');

	$('.site-footer .box-1 .info').removeClass('is-active');
	$('.js_changeFooterInfo').removeClass('is-active');
	$this.addClass('is-active');

	// $box.css('height', height);
	$activeInfo.addClass('is-active');
});

$(document).on('mouseenter touchenter', '.js_resetFooterInfo', function() {
	$('.site-footer .box-1 .info').removeClass('is-active');
	$('.js_changeFooterInfo').removeClass('is-active');
	$('.site-footer .box-1 .info-1').addClass('is-active');

	// console.log('footer leave');
});

/*=====================================
=            footer reveal            =
=====================================*/

setTimeout(function() {
	$('footer').footerReveal({ shadow: false});
}, 500);

/*============================================
=            set introanim cookie            =
============================================*/


function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+";";
}

createCookie('introanim','isset','30');



/*=====================================
=            header reveal            =
=====================================*/
function createTopWrapper() {
	// var $win = $(window);
	// if ($this.outerHeight() <= $win.outerHeight() && $this.offset().top >= $win.outerHeight()) {

	// }

	var fixedNumber = $('.site').attr('data-fixed-element-number');
	var paddingTopClasses = $('.site').attr('data-fixed-element-mt');
	var bgColor = $('.site').attr('data-bg-color');
	var $first = $('.site .section').first();
	if (fixedNumber == 'one') {
		$first.addClass('item-to-wrap');
	} else if(fixedNumber == 'two') {
		$first.addClass('item-to-wrap');
		$first.next().addClass('item-to-wrap');

	} else if(fixedNumber == 'three') {
		$first.addClass('item-to-wrap');
		$first.next().addClass('item-to-wrap');
		$first.next().next().addClass('item-to-wrap');

	} else {
		// $first.addClass('item-to-wrap');
		// $first.next().addClass('item-to-wrap');
		// $first.next().next().addClass('item-to-wrap');
	}
	$('.item-to-wrap').wrapAll( "<div class='header-reveal' />");
	$('.site .section').not('.item-to-wrap').wrapAll( "<div class='content-reveal' />");
	$('.content-reveal').css('background-color', bgColor).addClass(paddingTopClasses);
	$('.header-reveal').css('background-color', bgColor);
}

createTopWrapper();



$('.header-reveal').footerReveal({ shadow: false, isBottom: false});
setTimeout(function() {
	if ($('.header-reveal').css('position') == 'fixed') {
		initWaypointHeaderReveal('.content-reveal');
	}
}, 100);


function initWaypointHeaderReveal(ele) {
	var $ele = $(ele);
	var $win = $(window);
	var winWidth = $win.outerWidth();
	if ($ele.length && winWidth > 900) {

			var el = document.querySelector(ele);
			// var el = document.querySelector('.site');

			var waypoint = new Waypoint({
			    element: el,
			    handler: function(direction) {
			    	// var slug = $(el).attr('id');
			    	// console.log('trigger');
			        if (direction == 'down') {
			        	$('.header-reveal').css('display', 'none');
			        	// console.log('trigger down');
			        } else {
			        	$('.header-reveal').css('display', 'block');
			        	// console.log('trigger up');
			        }
			    }, offset: '-100%'
			})
	} else {
		Waypoint.destroyAll();
	}
}

/*======================================
=            handle blendin            =
======================================*/
// var randomInt = 1
var current = $('.blendin-item').not('is-hidden').attr('data-index');

function changeBlendin() {
	var blendinLength = $('.blendin-item').length;
	var random = getRandomInt(1,blendinLength);

	if (random != current) {
		$('.blendin-item').addClass('is-hidden');
		setTimeout(function() {
			$('.blendin-item-' + random).removeClass('is-hidden');
			current = random;
		}, 100);
	}
	setTimeout(changeBlendin, 6000);
}
changeBlendin();
// setInterval(changeBlendin(), 6000);

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/*========================================
=            change inverted             =
========================================*/

function changeInverted() {
	var $nav = $('.nav'),
		$site = $('.site'),
		$footer = $('.site-footer'),
		$siteHider = $('.js_siteHider'),
		inverted = $('.site').hasClass('is-inverted');

	if (inverted) {
		$site.addClass('is-inverted');
		$nav.addClass('is-inverted');
		$footer.addClass('is-inverted');
		$siteHider.addClass('is-inverted');
	} else {
		$site.removeClass('is-inverted');
		$nav.removeClass('is-inverted');
		$footer.removeClass('is-inverted');
		$siteHider.removeClass('is-inverted');
	}
}

/*==============================================
=            open/close mobile menu            =
==============================================*/

if ($('.js_openMobileMenu').length) {
	$(document).on('click', '.js_openMobileMenu', function() { // touchstart
		var $this = $(this);
		var $navWrapper = $this.closest('.nav').find('.nav-wrapper');
		var $nav = $this.closest('.nav');
		if ($navWrapper.hasClass('is-open')) {
			bodyScroll.enable();
			closeMenu();
		} else {
			bodyScroll.disable();
			$this.addClass('is-open');
			$navWrapper.addClass('is-open');
			$nav.addClass('is-open');
		}
	});
}

function closeMenu() {
	$('.nav-wrapper').removeClass('is-open');
	$('.nav').removeClass('is-open');
	$('.js_openMobileMenu').removeClass('is-open');
}



/*==========================================
=            follow mouse hover            =
==========================================*/


function setmouseMovesMenu(lineStandard) {
	$(document).on('mousemove', '.jsFollowMouseHover', function(e) {
		var $this = $(this);
		var $activeLine = $this.find('.active-line');
		var oldStyles = $activeLine.attr('style');

		// var attr = $activeLine.attr('data-old-styles');
		// if (typeof attr == typeof undefined && attr == false) {
		// }

		if ($activeLine.data('old-styles')) {
			$activeLine.attr('data-old-styles', oldStyles);
		}

		left = e.pageX - $this.offset().left;
		$activeLine.css({'left': left-25 + 'px', 'width' : 50 + 'px', 'transition': 'unset'})
		// console.log('mousemove');
	})

	$(document).on('mouseleave', '.jsFollowMouseHover', function(e) {
		var $this = $(this);
		var $activeLine = $this.find('.active-line');
		var oldStyles = $activeLine.attr('data-old-styles');
		// left = e.pageX - $this.offset().left;
		if (typeof lineStandard != "undefined") {
			// $activeLine.attr('style', oldStyles);
			$activeLine.animate(lineStandard, 500, 'swing');
		}
		// $activeLine.removeAttr('style');
		// console.log('mousemove');
	})
}

/*====================================
=            close cookie            =
====================================*/

$(document).on('click', '.js_closecookie', function() {
	$(this).closest('.cookiemsg').removeClass('is_visible');
});


// setTimeout(function() {
	new WOW().init();
// }, 100);

/*====================================================
=            activate browser back reload            =
====================================================*/

// window.addEventListener( "pageshow", function ( event ) {
// 	// var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
// 	// console.log('pageshow trigger');
// 	// if ( historyTraversal ) {
// 	// 	// Handle page restore.
// 	// 	console.log('history back trigger');
// 	// 	window.location.reload();
// 	// }

// });

// if(performance.navigation.type == 2){
// 	location.reload(true);
// }
 $(window).on('popstate', function() {
  location.reload(true);
});

/*====================================
=            resize stuff            =
====================================*/

	function resizeInit() {
		$('footer').footerReveal({ shadow: false});
	}

	window.onresize = resizeInit.debounce(1000);


    setTimeout(function() {
        $('.js_siteHider').removeClass('is_fading');
        $('.site').removeClass('has_animation');
        // $('html').removeClass('is_fading');
        $('svg').removeAttr('style');
    }, 200);
});

// $(window).load(function() {
	
// })

})(jQuery);